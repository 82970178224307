import { Controller } from 'stimulus';
import jQuery from 'jquery';

import PermissionsEvent from '../../admin_portal/permissionsEvent';
import ArticlePermission from '../../admin_portal/articlePermission';
import { appendPermissions } from '../../admin_portal/appendPermissions';
import { instance } from '../../common';

export default class extends Controller {
  static targets = ['articlePermissions', 'boardSelect'];

  async initialize() {
    const boardId = this.boardSelectTarget.dataset.board;
    const data = await this.fetchBoardPermissions(boardId);
    const articlePermission = new ArticlePermission(data);

    appendPermissions(this.articlePermissionsTarget, articlePermission);
    this.permissionsEvent.setEvent();
    this.permissionsEvent.isPermittedBoard = articlePermission.isPermittedBoard;
  }

  async boardChange(_event) {
    const oldBoardId = this.boardSelectTarget.dataset.board;
    const currentBoardId = jQuery(this.boardSelectTarget).val();

    if (window.confirm('掲示板を変更すると記事の閲覧制限がリセットされます。\nよろしいですか？')) {
      const data = await this.fetchBoardPermissions(currentBoardId);
      const articlePermission = new ArticlePermission(data);

      appendPermissions(this.articlePermissionsTarget, articlePermission);
      this.permissionsEvent.isPermittedBoard = articlePermission.isPermittedBoard;
      this.permissionsEvent.switchSlackNotify();
    } else {
      jQuery(this.boardSelectTarget).val(oldBoardId);
    }
  }

  private async fetchBoardPermissions(boardId) {
    const response = await instance.get(`/api/admin_portal/boards/${boardId}/permissions`);

    return response.data;
  }

  private get articlePermissionsTarget(): HTMLElement {
    return this.targets.find('articlePermissions') as HTMLElement;
  }

  private get boardSelectTarget(): HTMLElement {
    return this.targets.find('boardSelect') as HTMLElement;
  }

  private get permissionsEvent(): PermissionsEvent {
    return PermissionsEvent.instance;
  }
}
