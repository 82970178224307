import jQuery from 'jquery';

class PermissionsEvent {
  private static _instance: PermissionsEvent;
  private _isPermittedBoard: boolean;

  private constructor() {}

  static get instance(): PermissionsEvent {
    if (!this._instance) {
      this._instance = new PermissionsEvent();
    }

    return this._instance;
  }

  get isPermittedBoard(): boolean {
    return this._isPermittedBoard;
  }

  set isPermittedBoard(isPermittedBoard: boolean) {
    this._isPermittedBoard = isPermittedBoard;
  }

  setEvent() {
    jQuery(document).on('click', '.company_arrow', e => {
      const companyId = e.target.dataset.company;
      const departmentPermission = jQuery(`#company_${companyId}_departments`);
      const userPermissions = departmentPermission.find('.user_permissions_open');

      if (departmentPermission.hasClass('department_permissions')) {
        departmentPermission.removeClass('department_permissions');
        departmentPermission.removeAttr('style');
        departmentPermission.addClass('department_permissions_open');
      } else {
        departmentPermission.removeClass('department_permissions_open');
        departmentPermission.addClass('department_permissions');

        userPermissions.toArray().forEach(ul => {
          jQuery(ul).addClass('user_permissions');
          jQuery(ul).removeClass('user_permissions_open');
        });
      }
    });

    jQuery(document).on('click', '.department_arrow', e => {
      const departmentId = e.target.dataset.department;
      const departmentPermission = jQuery(`#department_${departmentId}_users`);

      if (departmentPermission.hasClass('user_permissions')) {
        departmentPermission.removeClass('user_permissions');
        departmentPermission.removeAttr('style');
        departmentPermission.addClass('user_permissions_open');
      } else {
        departmentPermission.removeClass('user_permissions_open');
        departmentPermission.addClass('user_permissions');
      }
    });

    // 「全てのユーザーに公開する」にチェックを入れた時
    jQuery(document).on('change', '#permission_to_all', () => {
      jQuery('.company_permissions').toggle('fast');

      if (jQuery('#permission_to_all').prop('checked')) {
        this.resetPermissions();
      }

      this.switchSlackNotify();
    });

    jQuery(document).on('change', '.company_permission_checked', e => {
      const companyId = e.target.dataset.company;
      const departmentPermissions = jQuery(`#company_${companyId}_departments`);
      const departmentLabels = departmentPermissions.find('.department_permission').toArray();

      const departmentCheckboxes = departmentPermissions.find('.department_permission_checked').toArray();
      const userCheckboxes = departmentPermissions.find('.user_permission_checked').toArray();

      if (jQuery(`#company_${companyId}_checked`).prop('checked')) {
        // 「会社名」にチェックが入った時
        departmentCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', true));
        userCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', true));
        if (!jQuery(`#company_${companyId}_permission`).children('.selected').length) {
          jQuery(`#company_${companyId}_permission`).append("<div class='selected'>選択中</div>");
        }

        departmentLabels.forEach(el => {
          // 「部署」全てに選択中が追加される
          if (!jQuery(el).children('.selected').length) {
            jQuery(el).append("<div class='selected'>選択中</div>");
          }
        });
      } else {
        // 「会社名」からチェックが外れた時
        departmentCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', false));
        userCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', false));
        jQuery(`#company_${companyId}_permission`).children('.selected').remove();
        departmentLabels.forEach(el => {
          //「部署」全てから選択中が外れる
          jQuery(el).children('.selected').remove();
        });
      }
      this.switchSlackNotify();
    });

    jQuery(document).on('change', '.department_permission_checked', e => {
      const companyId = e.target.dataset.company;
      const departmentId = e.target.dataset.department;
      const companyCheckbox = jQuery(`#company_${companyId}_checked`);

      const companyPermissions = jQuery(`#company_${companyId}_permissions`);
      const departmentPermissions = jQuery(`#company_${companyId}_departments`);
      const userPermissions = jQuery(`#department_${departmentId}_users`);

      const allUserCheckboxes = companyPermissions.find('.user_permission_checked').toArray();
      const departmentCheckboxes = departmentPermissions.find('.department_permission_checked').toArray();
      const userCheckboxes = userPermissions.find('.user_permission_checked').toArray();
      const isAllDepartmentChecked = departmentCheckboxes.every(checkbox => jQuery(checkbox).prop('checked'));
      const isAllDepartmentUnChecked = departmentCheckboxes.every(checkbox => !jQuery(checkbox).prop('checked'));

      if (jQuery(`#department_${departmentId}_checked`).prop('checked')) {
        // 「部署名」にチェックが入った時
        userCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', true));

        if (!jQuery(`#company_${companyId}_permission`).children('.selected').length) {
          jQuery(`#company_${companyId}_permission`).append("<div class='selected'>選択中</div>");
        }
        if (!jQuery(`#department_${departmentId}_permission`).children('.selected').length) {
          jQuery(`#department_${departmentId}_permission`).append("<div class='selected'>選択中</div>");
        }

        if (isAllDepartmentChecked) {
          companyCheckbox.prop('checked', true);
        }
      } else {
        // 「部署名」からチェックが外れた場合
        jQuery(`#department_${departmentId}_permission`).children('.selected').remove();
        userCheckboxes.forEach(checkbox => jQuery(checkbox).prop('checked', false));
        const isAllUserUnChecked = allUserCheckboxes.every(checkbox => !jQuery(checkbox).prop('checked'));

        if (!isAllDepartmentChecked) {
          companyCheckbox.prop('checked', false);
        }
        if (isAllDepartmentUnChecked && isAllUserUnChecked) {
          jQuery(`#company_${companyId}_permission`).children('.selected').remove();
        }
      }
      this.switchSlackNotify();
    });

    jQuery(document).on('change', '.user_permission_checked', e => {
      const companyId = e.target.dataset.company;
      const departmentId = e.target.dataset.department;
      const userId = e.target.dataset.user;

      const companyCheckbox = jQuery(`#company_${companyId}_checked`);
      const userCheckbox = jQuery(`#user_${userId}_checked`);
      const departmentCheckbox = jQuery(`#department_${departmentId}_checked`);

      const companyPermissions = jQuery(`#company_${companyId}_permissions`);
      const departmentPermissions = jQuery(`#department_${departmentId}_users`);

      const userCheckboxes = departmentPermissions.find('.user_permission_checked').toArray();
      const allUserCheckboxes = companyPermissions.find('.user_permission_checked').toArray();

      const isDepartmentUserChecked = userCheckboxes.every(checkbox => jQuery(checkbox).prop('checked'));
      const isAllUserChecked = allUserCheckboxes.every(checkbox => jQuery(checkbox).prop('checked'));
      const isDepartmentUserUnChecked = userCheckboxes.every(checkbox => !jQuery(checkbox).prop('checked'));
      const isAllUserUnChecked = allUserCheckboxes.every(checkbox => !jQuery(checkbox).prop('checked'));

      if (userCheckbox.prop('checked')) {
        //「ユーザー名」にチェックが入った時
        if (isDepartmentUserChecked) {
          departmentCheckbox.prop('checked', true);
        }
        if (isAllUserChecked) {
          companyCheckbox.prop('checked', true);
        }
        if (!jQuery(`#company_${companyId}_permission`).children('.selected').length) {
          jQuery(`#company_${companyId}_permission`).append("<div class='selected'>選択中</div>");
        }
        if (!jQuery(`#department_${departmentId}_permission`).children('.selected').length) {
          jQuery(`#department_${departmentId}_permission`).append("<div class='selected'>選択中</div>");
        }
      } else {
        // 「ユーザー名」からチェックが外れた時
        if (!isDepartmentUserChecked) {
          departmentCheckbox.prop('checked', false);
        }
        if (!isAllUserChecked) {
          companyCheckbox.prop('checked', false);
        }
        if (isDepartmentUserUnChecked) {
          jQuery(`#department_${departmentId}_permission`).children('.selected').remove();
        }
        if (isAllUserUnChecked) {
          jQuery(`#company_${companyId}_permission`).children('.selected').remove();
        }
      }
      this.switchSlackNotify();
    });

    jQuery(document).on('change', '#slack_notify_true', () => {
      const checked = jQuery('#slack_notify_true').prop('checked');
      if (!checked) {
        return;
      }
      this.removeAnnotation();
    });

    jQuery(document).on('change', '#slack_notify_false', () => {
      const checked = jQuery('#slack_notify_false').prop('checked');
      if (!checked) {
        return;
      }
      this.displayAnnotation();
    });
  }

  switchSlackNotify() {
    if (this.isSlackNotifiable()) {
      this.enableSlackNotify();
    } else {
      this.disableSlackNotify();
    }
  }

  private isSlackNotifiable() {
    if (this.isPermittedBoard || this.isNotConfigured() || this.isPermittedDepartmentsAndUsers()) {
      return false;
    } else {
      return true;
    }
  }

  private disableSlackNotify() {
    const slackNotifyFalse = jQuery('#slack_notify_false');
    const slackNotifyTrue = jQuery('#slack_notify_true');

    slackNotifyTrue.prop('disabled', true);
    slackNotifyFalse.prop('disabled', true);
    slackNotifyTrue.prop('checked', false);
    slackNotifyFalse.prop('checked', true);

    this.displayAnnotation();
  }

  private enableSlackNotify() {
    const slackNotifyFalse = jQuery('#slack_notify_false');
    const slackNotifyTrue = jQuery('#slack_notify_true');

    slackNotifyTrue.prop('disabled', false);
    slackNotifyFalse.prop('disabled', false);
    slackNotifyTrue.prop('checked', true);
    slackNotifyFalse.prop('checked', false);

    this.removeAnnotation();
  }

  private displayAnnotation() {
    if (jQuery('#slack-annotation').length) {
      return;
    }
    const slackNotify = jQuery('#slack-notify');
    const form = slackNotify.find('.form-group');
    form.append(
      '<div id="slack-annotation" class="article_annotation">*通知出来る条件を満たしていません。掲示板・閲覧ユーザーを確認して下さい。</div>'
    );
  }

  private removeAnnotation() {
    jQuery('#slack-annotation').remove();
  }

  private isNotConfigured() {
    const companyCheckboxes = jQuery('input[name="article[permission][companies][][checked]"]').toArray();

    return !this.isToAllChecked() && companyCheckboxes.every(checkbox => !checkbox.checked);
  }

  private isToAllChecked() {
    return jQuery('#permission_to_all').prop('checked');
  }

  private isPermittedDepartmentsAndUsers() {
    const uncheckedCompaniesList = this.getUncheckedCompaniesList();

    return uncheckedCompaniesList.some(li => {
      const departmentCheckboxes = jQuery(li).find('input[name="article[permission][companies][][departments][][checked]"]').toArray();
      const userCheckboxes = jQuery(li).find('input[name="article[permission][companies][][departments][][users][][checked]"]').toArray();

      return this.isAnyChecked(departmentCheckboxes) || this.isAnyChecked(userCheckboxes);
    });
  }

  private getUncheckedCompaniesList() {
    const companyCheckboxes = jQuery('input[name="article[permission][companies][][checked]"]').toArray();
    const uncheckedCompanyCheckboxes = companyCheckboxes.filter(checkbox => !checkbox.checked);
    return uncheckedCompanyCheckboxes.map(checkbox => {
      const companyId = checkbox.dataset.company;

      return jQuery(`#company_${companyId}_permissions`);
    });
  }

  private isAnyChecked(checkboxes) {
    return checkboxes.some(checkbox => checkbox.checked);
  }

  private resetPermissions() {
    this.removeSelected();
    this.uncheckAllPermissions();
    this.hideAllPermissions();
  }

  private uncheckAllPermissions() {
    this.uncheckAll(jQuery('.company_permission_checked').toArray());
    this.uncheckAll(jQuery('.department_permission_checked').toArray());
    this.uncheckAll(jQuery('.user_permission_checked').toArray());
  }

  private removeSelected() {
    jQuery('.selected')
      .toArray()
      .forEach(selected => selected.remove());
  }

  private hideAllPermissions() {
    this.hideUl(jQuery('.department_permissions_open').toArray(), 'department');
    this.hideUl(jQuery('.user_permissions_open').toArray(), 'user');
  }

  private hideUl(uls, unit) {
    uls.forEach(ul => {
      jQuery(ul).removeClass(`${unit}_permissions_open`);
      jQuery(ul).addClass(`${unit}_permissions`);
    });
  }

  private uncheckAll(checkboxes) {
    checkboxes.forEach(checkbox => jQuery(checkbox).prop('checked', false));
  }
}

export default PermissionsEvent;
