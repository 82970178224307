import $ from "jquery";

$(document).on('mouseenter','.c-tooltip', function(){
    const text = $(this).attr('data-text');
    const place = $(this).attr('data-place');
    $(this).append(`<div class="c-tooltips ${place}-position">${text}</div>`);
  }
);
$(document).on('mouseleave', '.c-tooltip', function(){
    $(this).find('.c-tooltips').remove();
  }
);