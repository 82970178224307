import jQuery from 'jquery';

import { CompanyPermission, DepartmentPermission } from '../types/permission';
import UsersList from './UsersList';

class DepartmentsList {
  private company: CompanyPermission;

  constructor(company: CompanyPermission) {
    this.company = company;
  }

  render() {
    const ul = jQuery('<ul>', {
      id: `company_${this.company.id}_departments`,
      class: 'department_permissions',
    });
    const lis = this.company.departments.map(department => this.createItem(department));
    ul.append(lis);

    return ul;
  }

  private createItem(department: DepartmentPermission) {
    const li = jQuery('<li>', {
      class: 'custom-control custom-checkbox mt-3',
    });

    const div = jQuery('<div>', {
      id: `department_${department.id}_permission`,
      class: 'd-flex align-items-center department_permission',
    });

    const hidden = jQuery('<input>', {
      type: 'hidden',
      name: 'article[permission][companies][][departments][][department_id]',
      value: department.id,
    });

    const checkbox = jQuery('<input>', {
      type: 'checkbox',
      id: `department_${department.id}_checked`,
      class: 'custom-control-input department_permission_checked',
      name: 'article[permission][companies][][departments][][checked]',
    }).attr({ 'data-department': department.id, 'data-company': this.company.id });
    if (department.checked) {
      checkbox.prop('checked', true);
    }

    const label = jQuery('<label>', {
      for: `department_${department.id}_checked`,
      class: 'custom-control-label permission_label mr-1',
    }).html(department.name);

    const icon = jQuery('<i>', {
      class: 'fas fa-caret-down department_arrow arrow',
    }).attr('data-department', department.id);

    const usersList = new UsersList(this.company, department);

    div.append(hidden, checkbox, label, icon);
    if (department.selected) {
      const selected = jQuery('<div>', {
        class: 'selected',
      }).html('選択中');
      div.append(selected);
    }
    li.append(div, usersList.render());

    return li;
  }
}

export default DepartmentsList;
