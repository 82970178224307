import jQuery from 'jquery';

import PermissionsList from '../../src/admin_portal/PermissionsList';
import ArticlePermission from '../../src/admin_portal/articlePermission';

export const appendPermissions = (target: HTMLElement, articlePermission: ArticlePermission): void => {
  const permissionsList = new PermissionsList(articlePermission.isToAllChecked(), articlePermission.formatCompanies());
  const { toAll, companies } = permissionsList.render();

  jQuery(target).empty();
  jQuery(target).append(toAll, companies);
};
