import jQuery from 'jquery';

import { CompanyPermission } from '../types/permission';
import DepartmentsList from './DepartmentsList';

class CompaniesList {
  private toAll: boolean;
  private companies: Array<CompanyPermission>;

  constructor(toAll: boolean, companies: Array<CompanyPermission>) {
    this.toAll = toAll;
    this.companies = companies;
  }

  render() {
    const ul = jQuery('<ul>', {
      class: 'company_permissions',
    });
    if (!this.toAll) {
      ul.addClass('company_permissions_open');
    }

    const items = this.companies.map(company => this.createItem(company));
    ul.append(items);

    return ul;
  }

  private createItem(company: CompanyPermission) {
    const li = jQuery('<li>', {
      id: `company_${company.id}_permissions`,
      class: 'custom-control custom-checkbox mt-3',
    });

    const div = jQuery('<div>', {
      id: `company_${company.id}_permission`,
      class: 'd-flex align-items-center',
    });

    const hidden = jQuery('<input>', {
      type: 'hidden',
      name: 'article[permission][companies][][company_id]',
      value: company.id,
    });

    const checkbox = jQuery('<input>', {
      type: 'checkbox',
      id: `company_${company.id}_checked`,
      class: 'custom-control-input company_permission_checked',
      name: 'article[permission][companies][][checked]',
    }).attr('data-company', company.id);
    if (company.checked) {
      checkbox.prop('checked', true);
    }

    const label = jQuery('<label>', {
      for: `company_${company.id}_checked`,
      class: 'custom-control-label permission_label mr-1',
    }).html(company.name);

    const icon = jQuery('<i>', {
      class: 'fas fa-caret-down company_arrow arrow',
    }).attr('data-company', company.id);

    const departmentList = new DepartmentsList(company);
    div.append(hidden, checkbox, label, icon);
    if (company.selected) {
      const selected = jQuery('<div>', {
        class: 'selected',
      }).html('選択中');
      div.append(selected);
    }

    li.append(div, departmentList.render());
    return li;
  }
}

export default CompaniesList;
