import { Controller } from 'stimulus';
import jQuery from 'jquery';

import { instance } from '../../common';

export default class extends Controller {
  static targets = ['form'];

  async submit(event) {
    event.preventDefault();

    const href =　jQuery(this.formTarget).attr('action'); 
    const params = new FormData(jQuery(this.formTarget).get(0));

    try {
      const response = await instance.patch(href, params);
      location.href = response.headers.location;
    } catch (error) {
      const messages = this.buildValidateMessages(error.response.data.errors);
      messages.forEach(message => jQuery('#articleList').before(message));
      jQuery('body, html').animate({
        scrollTop: 0
      }, 500);
    }
  }

  private buildValidateMessages(messages) {
    return messages.map(message => {
      return jQuery('<div>', {
        text: message,
        class: 'alert alert-danger'
      });
    });
  }

  private get formTarget(): HTMLFormElement {
    return this.targets.find('form') as HTMLFormElement;
  }
}
