import { Controller } from 'stimulus';
import jQuery from 'jquery';

import PermissionsEvent from '../../admin_portal/permissionsEvent';
import ArticlePermission from '../../admin_portal/articlePermission';
import { instance } from '../../common';
import { appendPermissions } from '../../admin_portal/appendPermissions';

export default class extends Controller {
  static targets = ['articlePermissions', 'boardSelect'];

  async initialize() {
    const articleId = this.boardSelectTarget.dataset.article;
    const data = await this.fetchArticlePermissions(articleId);
    const articlePermission = new ArticlePermission(data);

    appendPermissions(this.articlePermissionsTarget, articlePermission);
    this.permissionsEvent.setEvent();
  }

  async boardChange(_event) {
    const oldBoardId = this.boardSelectTarget.dataset.board;
    const currentBoardId = jQuery(this.boardSelectTarget).val();
    const articleId = this.boardSelectTarget.dataset.article;

    if (window.confirm('掲示板を変更すると記事の閲覧制限がリセットされます。\nよろしいですか？')) {
      const data =
        currentBoardId === oldBoardId ? await this.fetchArticlePermissions(articleId) : await this.fetchBoardPermissions(currentBoardId);
      const articlePermission = new ArticlePermission(data);

      appendPermissions(this.articlePermissionsTarget, articlePermission);
    } else {
      jQuery(this.boardSelectTarget).val(currentBoardId);
    }
  }

  private async fetchBoardPermissions(boardId) {
    const response = await instance.get(`/api/admin_portal/boards/${boardId}/permissions`);

    return response.data;
  }

  private async fetchArticlePermissions(articleId) {
    const response = await instance.get(`/api/admin_portal/articles/${articleId}/permissions`);

    return response.data;
  }

  private get articlePermissionsTarget(): HTMLElement {
    return this.targets.find('articlePermissions') as HTMLElement;
  }

  private get boardSelectTarget(): HTMLElement {
    return this.targets.find('boardSelect') as HTMLElement;
  }

  private get permissionsEvent(): PermissionsEvent {
    return PermissionsEvent.instance;
  }
}
