import jQuery from 'jquery';

import { CompanyPermission, DepartmentPermission, UserPermission } from '../types/permission';

class UsersList {
  private company: CompanyPermission;
  private department: DepartmentPermission;

  constructor(company: CompanyPermission, department: DepartmentPermission) {
    this.company = company;
    this.department = department;
  }

  render() {
    const ul = jQuery('<ul>', {
      id: `department_${this.department.id}_users`,
      class: 'user_permissions',
    });
    const items = this.department.users.map(user => this.createItem(user));
    ul.append(items);

    return ul;
  }

  createItem(user: UserPermission) {
    const li = jQuery('<li>', {
      class: 'custom-control custom-checkbox mt-2 user_permission',
    });

    const hidden = jQuery('<input>', {
      type: 'hidden',
      name: 'article[permission][companies][][departments][][users][][user_id]',
      value: user.id,
    });

    const checkbox = jQuery('<input>', {
      type: 'checkbox',
      id: `user_${user.id}_checked`,
      class: 'custom-control-input user_permission_checked',
      name: 'article[permission][companies][][departments][][users][][checked]',
    }).attr({
      'data-company': this.company.id,
      'data-department': this.department.id,
      'data-user': user.id,
    });
    if (user.checked) {
      checkbox.prop('checked', true);
    }

    const label = jQuery('<label>', {
      for: `user_${user.id}_checked`,
      class: 'custom-control-label permission_label',
    }).html(user.name);

    li.append(hidden, checkbox, label);

    return li;
  }
}

export default UsersList;
