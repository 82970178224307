import $ from 'jquery';

$(document).on('change', '.form-check-input', e => {
  const article_id = e.target.dataset.target;
  const checked = $(`#already_read_checkbox${article_id}`).prop('checked');
  set_csrftoken();

  if (checked) {
    $.post('/already_reads', {
      already_read: { article_id: article_id }
    });
  } else {
    $.ajax({
      url: `/already_reads/${article_id}`,
      type: 'DELETE'
    })
  }
})

$(document).on('click', '.read_all', e => {
  e.preventDefault();

  if (confirm('全て既読にしてもよろしいですか？')) {
    const board_id = e.target.dataset.target;

    $(`#read_all_form_${board_id}`).submit();
  } else {
    return;
  }
})

$(document).on('submit', '#commentForm', e => {
  const value = $('#comment_content').val();
  if (!value) {
    e.preventDefault();
    if ($('#commentForm').find('.alert').length) {
      $('.alert').remove();
    }

    const message = $('<div>', {
      text: 'コメントを入力してください',
      class: 'alert alert-danger'
    });
    $('#commentForm').prepend(message);
    $('#commentButton').blur();
  }
})

$(document).on('click', '.cancel', () => {
  $('.modal').removeClass('open');
  $('#mce-modal-block').remove();
})

$(document).on('click', '.year_top', e => {
  const year = e.target.dataset.target;

  $(`.archives_${year}`).toggle('fast');
})

function set_csrftoken() {
  $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (!options.crossDomain) {
      const token = $('meta[name="csrf-token"]').attr('content');
      if (token) {
        return jqXHR.setRequestHeader('X-CSRF-Token', token);
      }
    }
  });
}

$(document).on('click', '#search_btn_side', () => {
  $('.article_search').submit();
})

$(document).on('click', '#search_btn_articles', () => {
  $('.article_search').submit();
})

$(document).on('click', '#search_btn_top', async () => {
  const form = $('#home_search_form');
  const keyword = $('#home_search').val();
  const condition = $('#home_search_form [name=condition]:checked').val();

  if (condition === '0') {
    form.submit();
  } else {
    const response = await $.get(`/api/neuron_link?word=${keyword}`);
    window.open(response.url);
  }
});
