import { instance } from '../common';

$(document).on('click', '[id^=category_]', async (e) => {
  const target = $('#goal_dream_id');
  const categoryId = e.target.dataset.categoryId;
  const title = e.target.getAttribute('alt')
  const response = await instance.get('/api/vision2030/dreams', { params: { category_id: categoryId } });
  if (response.status !== 200) {
    return;
  }
  $('[id^=category_]').each(function(){
    const title = $(this).attr('alt')
    $(this).attr('src', `/images/categories/${title}_unselect.png`)
  })
  $('#goal_category_id').val(categoryId);
  $('[id^=selected-id-]').attr('hidden', 'hidden');
  $(`#selected-id-${categoryId}`).removeAttr('hidden');
  $(`#category_${categoryId}`).attr('src', `/images/categories/${title}.png`)
  target.empty();
  response.data.forEach(category => {
    target.append(
      $('<option />')
        .val(category.id)
        .text(category.title_with_id)
    );
  });
});

$(document).on('click', '[id^=progress_]', e => {
  const progress_value = e.target.dataset.progress;
  $('#goal_progress').val(progress_value);
  $('[id^=progress_]').each(function(){
    const progress = $(this).data('progress')
    $(this).attr('src', `/images/progress/${progress}_unselect.png`)
  })
  $(`#progress_${progress_value}`).attr('src', `/images/progress/${progress_value}.png`)
  $('[id^=selected-progress-]').attr('hidden', 'hidden');
  $(`#selected-progress-${progress_value}`).removeAttr('hidden');
});

$(document).on('click', '.goal', async (e) => {
  const target = $(e.target).closest('.goal');
  const href = target.data('href');
  const response = await instance.get(href);

  const modal = $('.modal');
  $('body').addClass('modal-open');
  modal.empty().append(response.data);
  maskedBody();
  modal.addClass('open');
});

$(document).on('click', '.close', () => {
  const modal = $('.modal');
  modal.removeClass('open');
  $('body').removeClass('modal-open');
  $('.modal-back').remove();
});

function maskedBody() {
  const modal = $('.modal');
  modal.prepend('<div class="modal-back"></div>');

  $('.modal-back').on('click', () => {
    console.log('jpge');
    const modal = $('.modal');
    modal.removeClass('open');
    $('body').removeClass('modal-open');
    $('.modal-back').remove();
  });
}

$(document).on('change', '#q_user_company_id_eq', async (e) => {
  const target = $('#q_user_department_id_eq');
  const companyId = $('#q_user_company_id_eq').val();
  const response = await instance.get('/api/vision2030/departments', { params: { company_id: companyId } });
  if (response.status !== 200) {
    return;
  }
  target.empty();
  target.append(
    $('<option />')
      .val('')
      .text('部署名')
  );
  response.data.forEach(department => {
    target.append(
      $('<option />')
        .val(department.id)
        .text(department.name)
    );
  });
});