import jQuery from 'jquery';

import CompaniesList from './CompaniesList';
import { CompanyPermission } from '../types/permission';

class PermissionsList {
  private isToAllChecked: boolean;
  private permissions: Array<CompanyPermission>;

  constructor(isToAllChecked: boolean, permissions: Array<CompanyPermission>) {
    this.isToAllChecked = isToAllChecked;
    this.permissions = permissions;
  }

  render() {
    const toAll = this.createToAll();
    const companiesList = new CompaniesList(this.isToAllChecked, this.permissions);
    const companies = companiesList.render();

    return { toAll, companies };
  }

  private createToAll() {
    const div = jQuery('<div>', {
      class: 'custom-control custom-checkbox',
    });

    const input = jQuery('<input>', {
      type: 'checkbox',
      id: 'permission_to_all',
      class: 'custom-control-input',
      name: 'article[permission][to_all]',
    }).prop('checked', this.isToAllChecked);

    const label = jQuery('<label>', {
      for: 'permission_to_all',
      class: 'custom-control-label permission_label',
    }).html('全てのユーザーに公開する');

    div.append(input, label);

    return div;
  }
}

export default PermissionsList;
