import { Controller } from 'stimulus';
import PermissionsEvent from '../../admin_portal/permissionsEvent';

export default class extends Controller {
  initialize() {
    this.permissionsEvent.setEvent();
  }

  private get permissionsEvent(): PermissionsEvent {
    return PermissionsEvent.instance;
  }
}
