import {
  PermissionResponse,
  Company,
  Department,
  User,
  CompanyPermission,
  DepartmentPermission,
  UserPermission,
} from '../types/permission';
import Permissions from './permissions';

class ArticlePermission {
  private companies: Array<Company>;
  private boardPermissions: Permissions;
  private articlePermissions: Permissions;

  constructor(response: PermissionResponse) {
    this.companies = response.companies;
    this.boardPermissions = new Permissions(response.board_permissions);
    this.articlePermissions = new Permissions(response.article_permissions);
  }

  isToAllChecked(): boolean {
    return (
      (this.boardPermissions.isToAll() && this.articlePermissions.isToAll()) ||
      (!this.boardPermissions.isToAll() && this.articlePermissions.isToAll())
    );
  }

  formatCompanies(): Array<CompanyPermission> {
    const displayedCompanies = this.boardPermissions.length
      ? this.companies.filter(company => this.boardPermissions.isCompanySelected(company))
      : this.companies;

    return displayedCompanies.map(company => {
      return {
        id: company.id,
        name: company.name,
        checked: this.isCompanyChecked(company),
        selected: this.articlePermissions.isCompanySelected(company),
        departments: this.formatDepartments(company),
      };
    });
  }

  get isPermittedBoard(): boolean {
    return this.boardPermissions.length !== 0;
  }

  private formatDepartments(company: Company): Array<DepartmentPermission> {
    let displayedDepartments = this.boardPermissions.length
      ? company.departments.filter(department => this.boardPermissions.isDepartmentSelected(company, department))
      : company.departments;
    displayedDepartments = displayedDepartments.filter(department => department.users.length);

    return displayedDepartments.map(department => {
      return {
        id: department.id,
        name: department.name,
        checked: this.isDepartmentChecked(company, department),
        selected: this.articlePermissions.isDepartmentSelected(company, department),
        users: this.formatUsers(company, department),
      };
    });
  }

  private formatUsers(company: Company, department: Department): Array<UserPermission> {
    const displayedUsers = this.boardPermissions.length
      ? department.users.filter(user => this.boardPermissions.isUserSelected(company, department, user))
      : department.users;

    return displayedUsers.map(user => {
      return {
        id: user.id,
        name: user.name,
        checked: this.isUserChecked(company, department, user),
      };
    });
  }

  private isCompanyChecked(company: Company): boolean {
    return (
      this.articlePermissions.isPermittedCompany(company) ||
      this.articlePermissions.isAllDepartmentsPermitted(company) ||
      this.articlePermissions.isAllUsersPermitted(company)
    );
  }

  private isDepartmentChecked(company: Company, department: Department): boolean {
    return (
      this.articlePermissions.isPermittedCompany(company) ||
      this.articlePermissions.isPermittedDepartment(department) ||
      this.articlePermissions.isDepartmentUsersPermitted(department)
    );
  }

  private isUserChecked(company: Company, department: Department, user: User): boolean {
    return (
      this.articlePermissions.isPermittedCompany(company) ||
      this.articlePermissions.isPermittedDepartment(department) ||
      this.articlePermissions.isPermittedUser(user)
    );
  }
}

export default ArticlePermission;
