import axios from 'axios';

export const instance = axios.create({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  }
});

export const isEditUrl = () => {
  const currentURL = location.href.split('/').pop();
  return currentURL === 'edit' || currentURL.match(/^\d*$/) !== null;
}

export const disableButton = () => {
  $('.btn-request').prop('disabled', true);
}

export const enableButton = () => {
  $('.btn-request').prop('disabled', false);
}

export const onAjaxSuccess = redirectURL => {
  location.href = redirectURL;
}
