import { Permission, Company, Department, User } from '../types/permission';

class Permissions {
  private permissions: Array<Permission>;

  constructor(permissions: Array<Permission> | undefined) {
    this.permissions = permissions || [];
  }

  get length(): number {
    return this.permissions.length;
  }

  isToAll(): boolean {
    return this.permissions.length === 0;
  }

  isCompanySelected(company: Company): boolean {
    const departments = company.departments;
    const users = departments.flatMap(department => department.users);

    return this.isPermittedCompany(company) || this.isAnyDepartmentsPermitted(departments) || this.isAnyUsersPermitted(users);
  }

  isDepartmentSelected(company: Company, department: Department): boolean {
    return this.isPermittedCompany(company) || this.isPermittedDepartment(department) || this.isAnyUsersPermitted(department.users);
  }

  isUserSelected(company: Company, department: Department, user: User): boolean {
    return this.isPermittedCompany(company) || this.isPermittedDepartment(department) || this.isPermittedUser(user);
  }

  isPermittedCompany(company: Company): boolean {
    return this.permittedCompanies.some(permission => permission.id === company.id);
  }

  isPermittedDepartment(department: Department): boolean {
    return this.permittedDepartments.some(permission => permission.id === department.id);
  }

  isPermittedUser(user: User): boolean {
    return this.permittedUsers.some(permission => permission.id === user.id);
  }

  isAllDepartmentsPermitted(company: Company): boolean {
    const permittedDepartmentIds = this.permittedDepartments.map(department => department.id);

    return company.departments.every(department => permittedDepartmentIds.includes(department.id));
  }

  isAllUsersPermitted(company: Company): boolean {
    const users = company.departments.flatMap(department => department.users);
    const permittedUserIds = this.permittedUsers.map(user => user.id);

    return users.every(user => permittedUserIds.includes(user.id));
  }

  isDepartmentUsersPermitted(department: Department): boolean {
    return department.users.every(user => this.isPermittedUser(user));
  }

  private isAnyDepartmentsPermitted(departments: Array<Department>): boolean {
    return departments.some(department => this.isPermittedDepartment(department));
  }

  private isAnyUsersPermitted(users: Array<User>): boolean {
    return users.some(user => this.isPermittedUser(user));
  }

  private get permittedCompanies() {
    return this.permissions.filter(permission => permission.type === 'company');
  }

  private get permittedDepartments() {
    return this.permissions.filter(permission => permission.type === 'department');
  }

  private get permittedUsers() {
    return this.permissions.filter(permission => permission.type === 'user');
  }
}

export default Permissions;
